/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { registerSW } from "virtual:pwa-register";
import "../excalidraw-app/sentry";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import Dashboard from "./src/dashboard-page";
// import { Attendance } from "./src/teacher-attendance-page";
import ExcalidrawApp from "./App";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Login from "./src/Login";
import WhiteboardWithSlides from "./src/modules/whiteBoard/Swiper";
import Quiz from "./src/modules/popQuiz/Quiz";

import Dice from "./src/frags/Dice";
import FullScreenTimer from "./src/frags/FullScreenTimer";

// window.__EXCALIDRAW_SHA__ = import.meta.env.VITE_APP_GIT_SHA;
const rootElement = document.getElementById("root")!;
const root = createRoot(rootElement);

// // methods for timer
function handleTimerEnd () {
  // Handle what happens when the timer ends
  console.log("Time's up!");
};

// const WhiteboardWithSlides = () => (
//   <div style={{ position: 'relative', height: '100vh' }}>
//     {/* Swiper Component */}
//     <div
//       style={{
//         position: 'absolute',
//         top: 0,
//         left: 0,
//         right: 0,
//         zIndex: 10,
//         height: '20%',
//         backgroundColor: '#fff',
//       }}
//     >
//       <Swiper spaceBetween={30} slidesPerView={1} pagination={{ clickable: true }} navigation>
//         <SwiperSlide>
//           <img src="https://via.placeholder.com/400x300" alt="Slide 1" />
//         </SwiperSlide>
//         <SwiperSlide>
//           <img src="https://via.placeholder.com/400x300" alt="Slide 2" />
//         </SwiperSlide>
//         <SwiperSlide>
//           <img src="https://via.placeholder.com/400x300" alt="Slide 3" />
//         </SwiperSlide>
//       </Swiper>
//     </div>

//     {/* Excalidraw Whiteboard */}
//     <div
//       style={{
//         position: 'absolute',
//         top: '20%', // Adjust height for Swiper
//         left: 0,
//         right: 0,
//         bottom: 0,
//       }}
//     >
//       <ExcalidrawApp />
//     </div>
//   </div>
// );

registerSW();
root.render(
  <StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        {/* <Route path="/" element={<Dashboard />} /> */}
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/login" element={<Login />} />
        {/* <Route path="/dashboard-page" element={<Dashboard />} />  */}
        {/* <Route path="/teacher-attendance-page" element={<Attendance />} /> */}
        <Route path="/whiteboard" element={<ExcalidrawApp />} />
        <Route path="/swiper" element={<WhiteboardWithSlides />} />

        <Route path="/quiz" element={<Quiz />} />

        <Route path="/dice" element={<Dice />} />

        <Route
          path="/timer"
          element={<FullScreenTimer onTimerEnd={handleTimerEnd} />}
        />
      </Routes>
    </BrowserRouter>
  </StrictMode>,
);
