import React, { Component } from "react";
import { Dice1 as DiceIcon } from "lucide-react"; // Renaming the import for the icon
import { Button, Card, CardContent } from "@mui/material";
import "./Dice.css";
import CasinoOutlinedIcon from "@mui/icons-material/CasinoOutlined";
import CloseIcon from "@mui/icons-material/Close";

// Define the interface for the component state
interface DiceState {
  isRolling: boolean;
  rolledNumber: number | null;
}

class Dice extends Component<{}, DiceState> {
  // Initialize state in the constructor
  constructor(props) {
    super(props);
    this.state = {
      isRolling: false,
      rolledNumber: null,
    };

    // Bind the rollDice method
    this.rollDice = this.rollDice.bind(this);
  }

  rollDice() {
    this.setState({ isRolling: true, rolledNumber: null });

    setTimeout(() => {
      const randomNumber = Math.floor(Math.random() * 20) + 1;
      this.setState({ rolledNumber: randomNumber, isRolling: false });
    }, 2000);
  }

  handleClose = () => {
    // Define what happens when the close icon is clicked
    setTimeout(() => {
      window.history.back(); // Wait a second and navigate back
    }, 1000);
  };

  render() {
    const { isRolling, rolledNumber } = this.state;

    return (
      <div
        className="flex items-center justify-center min-h-screen bg-gradient-to-br from-blue-200 to-purple-300 p-4"
        style={{
          background: "linear-gradient(to bottom, #6a11cb 0%, #2575fc 100%)",
          minHeight: "100vh", // Ensures the background covers the whole viewport
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {/* <Card
          className="shadow-xl"
          style={{
            width: "350px", // Adjust the card width for better centering
            backgroundColor: "rgba(255, 255, 255, 0.85)",
            borderRadius: "20px",
            backdropFilter: "blur(10px)",
            padding: "20px",
          }}
        > */}
        <CardContent className="p-6 flex flex-col items-center space-y-6">
          <CloseIcon
            onClick={this.handleClose}
            className="absolute top-4 right-4 cursor-pointer text-white" // Positioning the icon
            style={{
              position: "absolute",
              bottom: "8px",
              right: "60px",
              cursor: "pointer",
              zIndex: 1000,
            }}
          />

          <div
            // className={`dice-container ${isRolling ? "rolling" : ""}`}
            className={`dice-display ${rolledNumber !== null && !isRolling ? "highlight" : ""}`}
            style={{
              backgroundImage: 'url(https://firebasestorage.googleapis.com/v0/b/classess-501e6.appspot.com/o/images%2Fdice-game.gif?alt=media&token=9f8f67f3-bfb8-406d-bb28-f30833d43d39)',
              // backgroundImage: 'url("../assets/dice-game.gif")',
              backgroundSize: "contain", // Adjusted to contain / cover
              backgroundPosition: "center",
              width: "150px",
              height: "150px",
              //borderRadius: "50%", // Make it circular
              //boxShadow: "0 4px 15px rgba(0, 0, 0, 0.2)",
              //transition: "transform 0.3s, box-shadow 0.3s", // Smooth transition for hover effect
              // animation: isRolling ? "flip 2s linear infinite" : "none",
              marginBottom: "15px",
            }}
          >
            {rolledNumber !== null && !isRolling && (
              <div
                className="text-2xl  font-bold  text-white rolled-number"
                style={{
                  // backgroundColor: "rgba(0,0,0,0.1)", // Light background for contrast
                  padding: "10px 20px",
                  //borderRadius: "10px",
                  // marginBottom: "10px",
                  fontSize: "45px",
                  fontWeight: "bold",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                  color: "rgb(255,15,0)",
                }}
              >
                {/* Roll Number:  */}
                {rolledNumber}
              </div>
            )}
          </div>

          {/* Display Rolled Number */}
          {/* {rolledNumber !== null && !isRolling && (
            <div
              className="text-2xl  font-bold  text-white"
              style={{
                // backgroundColor: "rgba(0,0,0,0.1)", // Light background for contrast
                padding: "10px 20px",
                //borderRadius: "10px",
                marginBottom: "10px",
                fontSize: "16px",
                fontWeight: "bold",
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              Roll Number :  
              {rolledNumber}
            </div>
          )} */}

          {/* Roll Button */}
          <Button
            onClick={this.rollDice}
            disabled={isRolling}
            className="w-full bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded-lg transition-colors"
            style={{
              backgroundColor: isRolling ? "#b0b0b0" : "#1976d2",
              color: "white",
              padding: "10px 35px",
              fontSize: "10px",
              borderRadius: "10px",
              transition: "background-color 0.3s",
              fontWeight: "bold",
              marginTop: "25px",
            }}
          >
            {isRolling ? "Rolling..." : "Roll the Dice"}
          </Button>
        </CardContent>
        {/* </Card> */}
      </div>
    );
  }
}

export default Dice;
