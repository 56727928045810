import React, { useState, useEffect, useCallback } from "react";
import { Box, Typography, Button, TextField, Tooltip } from "@mui/material";
import { PauseCircle, PlayCircle, StopCircle } from "lucide-react";
import Swal from "sweetalert2";

// Import sound files (make sure these files exist in your project)

const startSound = new Audio(
  "https://firebasestorage.googleapis.com/v0/b/classess-501e6.appspot.com/o/images%2FStartBuzzer.mp3?alt=media&token=88b7812c-2f29-42ec-aab9-9a8eb6c8ed28",
);
const endSound = new Audio(
  "https://firebasestorage.googleapis.com/v0/b/classess-501e6.appspot.com/o/images%2FEndBuzzer.mp3?alt=media&token=b45e63f7-eb6b-4f98-a3a7-4b0d9bd4e817",
);
const resetSound = new Audio(
  "https://firebasestorage.googleapis.com/v0/b/classess-501e6.appspot.com/o/images%2FEndBuzzer.mp3?alt=media&token=b45e63f7-eb6b-4f98-a3a7-4b0d9bd4e817",
);
// const endSound = new Audio("../../../assets/StopBuzzer.mp3"); // add another sound
// const resetSound = new Audio("../../../assets/StopBuzzer.mp3");

const FullScreenTimer = ({ initialDuration = 300, onTimerEnd }) => {
  const [timeLeft, setTimeLeft] = useState(initialDuration);
  const [isRunning, setIsRunning] = useState(false);
  const [showTimer, setShowTimer] = useState(false);
  const [customDuration, setCustomDuration] = useState<number | ''>(initialDuration / 60);
  const [position, setPosition] = useState<number>(50);
  const [isDragging, setIsDragging] = useState<boolean>(false);

  // Update timeLeft when customDuration is changed
  useEffect(() => {
    if (!isRunning && customDuration) {
      setTimeLeft(customDuration * 60);
    }
  }, [customDuration, isRunning]);

  useEffect(() => {
    let interval;
    if (isRunning && timeLeft > 0) {
      interval = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      onTimerEnd();
    }
    return () => clearInterval(interval);
  }, [isRunning, timeLeft]);


  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
      .toString()
      .padStart(2, "0")}`;
  };

  // const handleStart = () => {
  //   setIsRunning(true);
  //   setShowTimer(true);
  //   startSound.play();
  //   Swal.fire({
  //     title: "Timer Started",
  //     text: "The countdown has begun!",
  //     icon: "success",
  //     timer: 1500,
  //     showConfirmButton: false,
  //     position: "top",
  //   });
  // };

  const handleStart = () => {
    if (customDuration && customDuration > 0) {
      setIsRunning(true);
      setShowTimer(true);
      startSound.play();
      Swal.fire({
        title: "Timer Started",
        text: "The countdown has begun!",
        icon: "success",
        timer: 1500,
        showConfirmButton: false,
        position: "top",
      });
    } else {
      Swal.fire({
        title: "Invalid Time",
        text: "Please set a valid time before starting the timer.",
        icon: "error",
        confirmButtonText: "OK",
        position: "top",
      });
    }
  };


  const handlePause = () => {
    setIsRunning(false);
    Swal.fire({
      title: "Timer Paused",
      text: "The countdown has been paused.",
      icon: "warning",
      timer: 1500,
      showConfirmButton: false,
      position: "top",
    });
  };

  const handleReset = () => {
    setIsRunning(false);
    if (typeof customDuration === 'number') {
      setTimeLeft(customDuration * 60);
    } else {
      setTimeLeft(initialDuration);
    }
    setShowTimer(false);
    resetSound.play();
    Swal.fire({
      title: "Timer Reset",
      text: "The timer has been reset.",
      icon: "info",
      timer: 1500,
      showConfirmButton: false,
      position: "top",
    });
  };


  // const handleDurationChange = (event) => {
  //   const newDuration = parseInt(event.target.value, 10);
  //   if (newDuration >= 1) {
  //     setCustomDuration(newDuration);
  //     setTimeLeft(newDuration * 60);
  //   }
  // };

  const handleDurationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (value === '') {
      setCustomDuration('');
    } else {
      const numValue = Number(value);
      if (!isNaN(numValue) && numValue > 0) {
        setCustomDuration(numValue);
      }
    }
  };
  

  // Dragging Logic
  const handleMouseDown = useCallback(() => {
    setIsDragging(true);
  }, []);

  const handleMouseUp = useCallback(() => {
    setIsDragging(false);
  }, []);

  const handleMouseMove = useCallback(
    (e: MouseEvent) => {
      if (isDragging) {
        const newPosition = (e.clientY / window.innerHeight) * 100;
        setPosition(Math.max(0, Math.min(newPosition, 100)));
      }
    },
    [isDragging],
  );

  useEffect(() => {
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [handleMouseMove, handleMouseUp]);

  //   const progress = (timeLeft / initialDuration) * 100;
  const calculateProgress = (): number => {
    if (typeof customDuration === 'number' && customDuration > 0) {
      return ((customDuration * 60 - timeLeft) / (customDuration * 60)) * 100;
    }
    return 0;
  };
  const progress = calculateProgress();

  if (!showTimer) {
    return (
      <Box
        sx={{
          position: "fixed",
          top: `${position}%`,
          right: 700, // adjust to center timer duration
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          cursor: isDragging ? "grabbing" : "grab",
          userSelect: "none",
          transform: "translateY(-50%)",
          zIndex: 1000,
        }}
        onMouseDown={handleMouseDown}
      >
        <TextField
          type="number"
          label="Timer Duration (min)"
          value={customDuration}
          onChange={handleDurationChange}
          variant="outlined"
          size="small"
          InputProps={{
            inputProps: { min: 0 },
          }}
          sx={{
            mt: 2,
            width: "150px",
            "& .MuiOutlinedInput-root": {
              borderRadius: "20px",
            },
          }}
        />
        <Tooltip
          title={isRunning ? "Pause Timer" : "Start Timer"}
          placement="bottom"
        >
          <Button
            variant="contained"
            color="primary"
            onClick={handleStart}
            sx={{
              mt: 1,
              borderRadius: "20px",
              minWidth: "150px",
              backgroundColor: "#28a745", // Green background for start button
              "&:hover": {
                backgroundColor: "#218838",
              },
            }}
          >
            {isRunning ? <PauseCircle /> : <PlayCircle />}
          </Button>
        </Tooltip>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        position: "fixed",
        top: `${position}%`,
        //top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgb(185,211,223)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 9999,
        cursor: isDragging ? "grabbing" : "grab",
        animation: `backgroundMove 5s infinite alternate`, // Background movement effect
        "@keyframes backgroundMove": {
          "0%": { backgroundPosition: "0% 50%" },
          "100%": { backgroundPosition: "100% 50%" },
        },
      }}
      onMouseDown={handleMouseDown}
    >
      <Box
        sx={{
          position: "relative",
          width: 300,
          height: 300,
          borderRadius: "50%",
          background: `conic-gradient(
            #19d24b ${progress}%,
            #e0e0e0 ${progress}% 100%
          )`,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: 3,
        }}
      >
        <Box
          sx={{
            width: 250,
            height: 250,
            borderRadius: "50%",
            background: "rgb(185,211,223)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="h1" sx={{ color: "white", mb: 4 }}>
            {formatTime(timeLeft)}
          </Typography>
        </Box>
      </Box>

      <Box sx={{ display: "flex", gap: 2 }}>
        {isRunning ? (
          <Button
            variant="contained"
            color="primary"
            onClick={handlePause}
            startIcon={<PauseCircle />}
          >
            Pause
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            onClick={handleStart}
            startIcon={<PlayCircle />}
          >
            Resume
          </Button>
        )}
        <Button
          variant="contained"
          color="secondary"
          onClick={handleReset}
          startIcon={<StopCircle />}
        >
          Reset
        </Button>
      </Box>
    </Box>
  );
};

export default FullScreenTimer;
